<template>
	<div class="bg-red-900 bg-opacity-10 mb-6" style="height:1px;"></div>
	<div
		class="flex flex-col md:flex-row gap-4 md:gap-0 md:justify-between items-center font-semibold text-red-900 text-opacity-40"
	>
		<div class="">
			Made with
			<span class="text-black">❤️</span>
			by
			<a
				target="_blank"
				class="cursor-pointer underline"
				href="https://apptodesign.com"
				>AppToDesign</a
			>
			in India
		</div>
		<a id="discord-icon" href="https://discord.gg/dZA7y7Z4TG">
			<img class="w-8 h-auto" src="@/assets/images/social/d4.webp" />
		</a>
		<div class="flex flex-row gap-6 items-center">
			<a
				target="_blank"
				class="cursor-pointer underline"
				href="https://apptodesign.com/terms"
				>Terms of Use</a
			>
			<a
				target="_blank"
				class="cursor-pointer underline"
				href="https://apptodesign.com/privacy"
				>Privacy</a
			>
		</div>
	</div>
</template>
<script>
export default {
	data() {
		return {};
	}
};
</script>

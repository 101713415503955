<template>
	<div class="w-full md:fixed z-50">
		<PQContainer :bg="'bg-nav'">
			<div
				class="py-6 md:py-4 xl:py-6 flex flex-row justify-between items-center"
			>
				<a href="/">
					<img
						class="h-12"
						src="@/assets/images/logo/PlanQ-Logo-Land.png"
					/>
				</a>
				<a
					class="gumroad-button"
					href="https://gum.co/planq"
					target="_blank"
				>
					Buy PlanQ
				</a>
				<!-- <a href="https://gum.co/planq" target="_blank">
					<div
						class="font-bold text-black border-b-8 
					border-yellow-400 bg-yellow-300 
					font-q-medium text-2xl md:text-3xl xl:text-4xl px-6 md:px-10 
					py-3 rounded-full focus:outline-none 
					flex flex-row items-center"
					>
						Get the
						<img
							class="w-6 h-auto md:w-auto md:h-10 ml-2"
							src="@/assets/images/logo/PlanQ-Logo-Square.png"
						/>
					</div>
				</a> -->
			</div>
		</PQContainer>
	</div>
</template>

<script>
import PQContainer from "../layouts/PQContainer";

export default {
	components: { PQContainer }
};
</script>

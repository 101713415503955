<template>
	<div>
		<!-- --------- Priorities makes the different. Be the different. ----------- -->
		<PQContainer :bg="'bg-yellow-800 bg-opacity-10'">
			<div class="mx-auto flex flex-row md:h-screen md:pt-36 xl:pt-24">
				<div class="grid grid-cols-1 md:grid-cols-2 items-center">
					<div class="w-full block md:hidden">
						<img
							class="w-56 mx-auto my-6"
							src="@/assets/images/ninja/Ninja-7.svg"
						/>
					</div>
					<div>
						<div
							class="text-4xl md:text-6xl xl:text-7xl font-q-bold text-left text-black"
						>
							<span style="color: #5C4FF9">Priorities</span>
							make the difference. Be the
							<span style="color: #5C4FF9">difference</span>.
						</div>
						<div
							class="pt-36 pb-36 md:pb-0 md:pt-12 xl:pt-12 w-full text-left text-4xl md:text-3xl xl:text-4xl font-q-semibold"
						>
							Loosing track procrastinating increases disasters.
							Focus is the 🔑
							<!-- <span class="text-black font-q-bold">key</span> -->
							to success. Now it's upto you what you wanna do..
							<br />
							<br />
							<a
								href="https://www.producthunt.com/posts/planq?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-planq"
								target="_blank"
								><img
									src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=288405&theme=light"
									alt="PlanQ - Priorities make the difference. Be the difference. | Product Hunt"
									style="width: 250px; height: 54px;"
									width="250"
									height="54"
							/></a>
						</div>
					</div>
					<div class="hidden md:block pl-6">
						<img
							class="ml-auto"
							src="@/assets/images/ninja/Ninja-7.svg"
						/>
					</div>
				</div>
			</div>
		</PQContainer>
		<PQContainer bg="py-24">
			<div class="py-24 text-left flex flex-col md:flex-row items-start">
				<!-- <img src="@/assets/images/memoji/Memoji-01.png" /> -->
				<div>
					<div class="text-6xl ">
						<span class="text-yellow-800 text-opacity-30 font-ad"
							>You're Still Here !</span
						>
					</div>
					<div class="text-9xl pq-c-b1 pt-12 md:pt-12">
						🤦‍♂️ Okk..
					</div>
					<span class="text-6xl text-yellow-400 font-ad">
						Here's the dealio !!
					</span>
				</div>
			</div>
		</PQContainer>
		<PQContainer bg="bg-yellow-400 h-4"></PQContainer>
		<PQContainer bg="bg-red-500 h-4"></PQContainer>
		<!-- ------------------- Otherwise You Can Continue ------------------- -->
		<PQContainer
			bg="bg-gradient-to-b from-indigo-700 via-purple-700 to-purple-600"
		>
			<div
				class="mx-auto flex flex-row  items-center py-48 md:py-24 md:h-screen"
			>
				<div
					class="text-left font-q-semibold text-4xl md:text-5xl leading-tight"
				>
					<span class="text-white text-opacity-50">
						We loose so much time to decide what to do first, when
						we have
						<span class="text-white font-q-bold">Laww..wT 😥</span>
						to do, within a
						<span class="text-white font-q-bold">tiny</span>
						time frame..
					</span>
					<div class="font-ad text-8xl text-yellow-300 pt-24 pb-12">
						Right ?
					</div>
					<span class="text-white font-q-bold"
						>What if the situation is unpredictable 🤷
					</span>
				</div>
			</div>
		</PQContainer>
		<PQContainer :bg="'bg-yellow-800 bg-opacity-10'">
			<div class="mx-auto flex flex-row  items-center py-48">
				<div
					class="text-black text-left font-q-semibold text-4xl md:text-5xl leading-tight"
				>
					👉 👉 &nbsp;We need to eliminate unnecessary task and
					<span class="text-yellow-400 font-q-extrabold"
						>😌 focus</span
					>
					on the
					<!-- font-ad text-7xl md:text-9xl -->
					<span class="text-red-500 font-q-extrabold">
						urgent
					</span>
					<span>&</span>
					<span class="text-green-500 font-q-extrabold">
						important
					</span>
					tasks.
				</div>
			</div>
		</PQContainer>
		<PQContainer bg="bg-yellow-400 h-4"></PQContainer>
		<PQContainer bg="bg-red-500 h-4"></PQContainer>
		<PQContainer
			bg="bg-gradient-to-b from-indigo-700 via-purple-700 to-purple-600"
		>
			<div class="mx-auto flex flex-row  items-center py-48">
				<div
					class="text-white text-opacity-50 text-left font-q-semibold text-4xl md:text-5xl leading-tight"
				>
					<span class="text-white font-q-extrabold"
						>Clear visualisation</span
					>
					is another important element to be
					<span class="text-white font-q-extrabold">productive</span>
					and
					<span class="text-white font-q-extrabold">focussed</span>.
					For this we need<br />
					<div
						class="text-6xl md:text-8xl font-q-extrabold text-yellow-300 text-ts-2-yellow"
					>
						The Eisenhower Box
					</div>
					+
					<span>Clear visualisation</span>.
				</div>
			</div>
		</PQContainer>
		<PQContainer bg="bg-red-500 h-4"></PQContainer>
		<PQContainer bg="bg-yellow-400 h-4"></PQContainer>
		<!-- ------------------- Ultimate Problem Solver ------------------- -->
		<PQContainer :bg="'bg-yellow-800 bg-opacity-10'">
			<div
				class="mx-auto flex flex-row  items-center py-48 md:py-24 md:h-screen"
			>
				<div
					class="text-black text-left font-q-semibold text-4xl md:text-5xl"
				>
					This will not 🎩 magically vanish your problems. You need to
					<div class="">
						<div
							class=" py-6 text-6xl md:text-7xl xl:text-9xl font-q-extrabold  pt-12"
						>
							🖖
							<span class="text-red-500 text-ts-2">
								Build a Habit
							</span>
						</div>
						<div class="text-black text-opacity-40 text-3xl">
							Build a Habit of
							<span class="text-black font-q-bold"
								>asking yourself</span
							>
							if a particular activity will bring you closer to
							your goals
						</div>
						<div
							class="py-6 text-6xl md:text-7xl xl:text-9xl font-q-extrabold  pt-12"
						>
							🧘‍♂️
							<span class="text-indigo-500 text-ts-2-green"
								>Focus
							</span>
						</div>
						<div class="text-black text-opacity-40 text-3xl">
							Overcome the
							<span class="text-black font-q-bold">
								illusion of productivity
							</span>
							caused by our natural tendency to focus on
							unimportant and urgent activities
						</div>
					</div>
				</div>
			</div>
		</PQContainer>
		<PQContainer bg="bg-yellow-400 h-4"></PQContainer>
		<PQContainer bg="bg-red-500 h-4"></PQContainer>
		<PQContainer
			bg="bg-gradient-to-b from-indigo-700 via-purple-700 to-purple-600"
		>
			<div
				class="mx-auto flex flex-row  items-center py-48 md:py-24 md:h-screen"
			>
				<div
					class="text-white text-opacity-50 text-left font-q-semibold text-4xl md:text-5xl leading-tight"
				>
					That why we have a
					<span class="font-q-bold text-white ">priority system</span
					>. The priority system only allows you to
					<span class="font-q-bold text-white "></span>focus on tasks
					that are
					<div
						class=" text-5xl md:text-6xl xl:text-8xl font-q-bold text-red-500 mt-12 py-6"
					>
						⏰
						<span class="text-ts-2">Nearing Deadlines</span>
					</div>
					<div
						class=" text-5xl md:text-6xl xl:text-8xl font-q-bold text-yellow-400 py-6"
					>
						🔔
						<span class="text-ts-2-yellow">Urgent & Important</span>
					</div>
				</div>
			</div>
		</PQContainer>
		<!-- ------------------- Then Why PlanQ ------------------- -->
		<PQContainer :bg="'bg-yellow-800 bg-opacity-10'">
			<div class="mx-auto flex flex-row items-center pt-36">
				<div>
					<!-- <div
						class="text-6xl md:text-7xl xl:text-9xl font-q-bold text-black py-6"
					>
						Then Why PlanQ ?
					</div> -->
					<div class="grid grid-cols-1 md:grid-cols-2 items-end">
						<div>
							<div
								class="text-black text-opacity-40 text-left font-q-semibold text-3xl leading-tight"
							>
								After intense experimentation we've created
								<span
									class="pt-6 pb-12 font-q-semibold text-gray-600 flex flex-row items-center text-5xl md:text-7xl"
								>
									Plan
									<img
										class="h-16 ml-1 mr-6"
										src="@/assets/images/logo/PlanQ-Logo-Square.png"
									/>
									Box
								</span>
								It provides a
								<span class="text-black">🧐</span> Bird's Eye
								View of what needs to be done, and to get rid of
								anything that doesn’t align with your long-term
								goals
								<span class="text-black">🎯</span>
								<div class="mt-10 mb-20">
									<!-- <a href="https://gum.co/planq" target="_blank">
									<div
										class="w-max mt-10 mb-20 font-bold border-b-8 border-yellow-400 text-black bg-yellow-300 font-q-medium text-2xl md:text-3xl xl:text-4xl px-6 md:px-10 py-3 rounded-full focus:outline-none"
									>
										<span
											class="text-black text-opacity-30 tracking-wider"
											>$59.99</span
										>
										Buy Now
									</div>
								</a> -->
									<a
										class="gumroad-button"
										href="https://gum.co/planq"
										target="_blank"
									>
										<span
											class="text-black text-opacity-30 tracking-wider"
											>$59.99</span
										>
										Buy from Gumroad
									</a>
								</div>
							</div>
						</div>
						<div>
							<img
								class="w-full round"
								src="@/assets/images/screens/7.png"
							/>
						</div>
					</div>
				</div>
			</div>
		</PQContainer>
		<PQContainer bg="bg-yellow-400 h-4"></PQContainer>
		<PQContainer bg="bg-red-500 h-4"></PQContainer>
		<PQContainer
			bg="bg-gradient-to-b from-indigo-700 via-purple-700 to-purple-600"
		>
			<div class="mx-auto flex flex-row items-center py-48 md:py-24">
				<div class=" font-q-bold text-white py-6">
					<div class="py-12 text-6xl md:text-7xl xl:text-9xl">
						We give you
					</div>
					<div
						class="grid grid-cols-1 md:grid-cols-2 items-center gap-12"
					>
						<div class="block md:hidden">
							<img
								class="w-full rounded-2xl"
								src="@/assets/images/screens/10.svg"
							/>
						</div>
						<div
							class="text-3xl md:text-4xl xl:text-5xl text-white text-opacity-40"
						>
							Viewing Delight for
							<span class="text-yellow-400 text-ts-2-yellow"
								>YOUR</span
							>
							personal workspace setup, be it
							<!-- 🌝 🌚 -->
							<span class="text-white"> LIGHT 🌗</span>
							<span class="text-black"> DARK</span>
						</div>
						<div class="hidden md:block">
							<img
								class="w-full rounded-2xl"
								src="@/assets/images/screens/10.svg"
							/>
						</div>
						<div>
							<img
								class="w-full rounded-2xl"
								src="@/assets/images/screens/9.svg"
							/>
						</div>
						<div
							class="text-3xl md:text-4xl xl:text-5xl text-white text-opacity-40"
						>
							<span class="text-white">🧐 Bird's eye view</span>
							of all your Tasks in the upcoming days, weeks &
							months
						</div>
						<div class="block md:hidden">
							<img
								class="w-full rounded-2xl"
								src="@/assets/images/screens/11.svg"
							/>
						</div>
						<div
							class="text-3xl md:text-4xl xl:text-5xl text-white text-opacity-40"
						>
							All your Urgent and Important Tasks in
							<span class="text-white">
								The Eisenhower Matrix
							</span>
							so you know what you need to
							<span class="text-red-500 text-ts-2"
								>get rid of</span
							>.
						</div>
						<div class="hidden md:block">
							<img
								class="w-full rounded-2xl"
								src="@/assets/images/screens/11.svg"
							/>
						</div>
					</div>
				</div>
			</div>
		</PQContainer>
		<!-- ---------------------- Feature List ---------------------- -->
		<!-- <div class="w-full ">
			<div class="container mx-auto q-sheet">
				<div
					class="text-left font-q-semibold text-black text-6xl md:text-7xl lg:text-8xl"
				>
					Feature List
				</div>
				<div
					class="grid grid-cols-2 md:grid-cols-3 pt-14 md:pt-20 gap-10 md:gap-20"
				>
					<div v-for="(feature, index) in features" :key="index">
						<img class="h-16 md:h-20" :src="feature.image" />
						<div
							class="text-left pt-4 md:pt-7 text-3xl md:text-4xl font-q-semibold text-black"
						>
							{{ feature.text }}
						</div>
					</div>
				</div>
			</div>
		</div> -->
		<!-- ---------------------- Visualisation ---------------------- -->
		<!-- <div class="w-full visualisation-background">
			<div class="container mx-auto q-sheet">
				<div
					class="text-left font-q-semibold text-black text-6xl md:text-7xl lg:text-8xl"
				>
					Visualisation
				</div>
			</div>
		</div> -->
		<!-- ---------------------- Get In Touch ---------------------- -->
		<PQContainer :bg="'bg-yellow-800 bg-opacity-10'">
			<div class="mx-auto flex flex-row items-center py-48 md:py-24">
				<div class="w-full ">
					<div class="container mx-auto q-sheet">
						<!-- <img
							class="h-32"
							src="@/assets/images/stamps-mail.png"
						/> -->
						<div
							class="pt-6 text-left font-q-extrabold text-indigo-600 text-ts-2-yellow text-5xl md:text-6xl lg:text-7xl"
						>
							Get In Touch
						</div>
						<div
							class="grid grid-cols-1 xl:grid-cols-2 gap-5 items-center "
						>
							<div>
								<div
									class="py-14 w-full text-left text-3xl font-q-semibold"
									style=" color: #1c1c1c; line-height:45px"
								>
									We know that task planning is an important
									task in itself! If there is anything that
									you're facing trouble understanding or clear
									out a query. Message us, we're owls...
								</div>
								<div
									class="flex flex-row bg-yellow-800 bg-opacity-10 text-black px-5 py-6 rounded-2xl items-center"
								>
									<img
										class="h-8 px-3"
										src="@/assets/images/email-action-heart-1.png"
									/>
									<div
										class="font-q-medium text-xl md:text-2xl"
									>
										Mail Us at
										<a
											href="mailto:touch@apptodesign.com"
											class="cursor-pointer underline"
											>touch@apptodesign.com</a
										>
									</div>
								</div>
								<div
									class="text-left text-3xl font-q-semibold text-black py-12"
								>
									If you think there is something we didn't
									cover? Message us! We'll get back ASAP..
								</div>
							</div>
							<div>
								<iframe
									class="w-max p-6 md:p-12 bg-white rounded-2xl my-12 support-form"
									src="https://chilipepper.io/form/burning-chopped-fresnos-665a8949-bd3b-49db-826c-72f19e3d0dec"
								></iframe>
							</div>
						</div>
						<Footer></Footer>
					</div>
				</div>
			</div>
		</PQContainer>
	</div>
</template>

<script>
import PQContainer from "../layouts/PQContainer";
import Footer from "../components/Footer";

export default {
	components: { PQContainer, Footer },
	data() {
		return {};
	}
};
</script>

<style scoped>
.support-form {
	width: 600px;
	height: 500px;
}

@media only screen and (max-width: 600px) {
	.support-form {
		width: 330px;
		height: 500px;
	}
}
</style>
